import { logout } from "@/actions/logout"

export interface RequestOptions {
  method?: string
  body?: object
  headers?: Record<string, string>
}

export const fetchJson = async <T>(
  url: string,
  options: RequestOptions = {}
) => {
  const response = await fetch(url, {
    ...options,
    method: options.method ?? "GET",
    headers: {
      "Content-Type": "application/json",
      ...options.headers,
    },
    body: options.body ? JSON.stringify(options.body) : undefined,
  })

  if (!response.ok) {
    if (response.status === 401) {
      // Ideally this is where we might go through refresh token flow but skipping it until we remove rodauth
      await logout()
      window.location.reload()
    } else {
      throw new Error(`Request to ${url} failed with status ${response.status}`)
    }
  }

  return response.json() as Promise<T>
}
