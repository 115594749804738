import { app, authentication } from "@microsoft/teams-js"

import { routes } from "@/config/routes"

import { fetchJson } from "../utils"
import {
  type SSOCallbackResponse,
  type SSOProviderResponse,
} from "./auth.types"

let isInitialized = false

/**
 * Safely initializes Teams app only once
 */
const initializeTeamsApp = async (): Promise<void> => {
  if (!isInitialized) {
    await app.initialize()
    isInitialized = true
  }
}

export const initializeTeamsContext = async (): Promise<void> => {
  await initializeTeamsApp()
}

/**
 * Checks if the application is running within Microsoft Teams
 */
export const runningInTeams = async (): Promise<boolean> => {
  try {
    await initializeTeamsApp()
    const context = await app.getContext()
    // More specific check for Teams context
    return !!context
  } catch (error) {
    return false
  }
}

/**
 * Gets the auth token when running in Teams
 */
export const getTeamsAuthToken = async (): Promise<string> => {
  await initializeTeamsApp()

  // Simplified auth token request
  const authTokenRequest = {
    resources: ["https://graph.microsoft.com"],
    silent: true,
  }

  // First try silent token acquisition
  try {
    const token = await authentication.getAuthToken(authTokenRequest)
    return token
  } catch (silentError) {
    // If silent fails, try with consent
    const tokenWithConsent = await authentication.getAuthToken({
      ...authTokenRequest,
      silent: false,
    })
    return tokenWithConsent
  }
}

/**
 * Requests Single Sign-On (SSO) provider information.
 */
export const ssoProvider = async (provider: string) => {
  let result: SSOProviderResponse
  // If running in Teams, use Teams SSO
  if (await runningInTeams()) {
    const token = await getTeamsAuthToken()

    result = await fetchJson<SSOProviderResponse>("/api/teams_auth/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        teams_token: token,
      },
    })
  } else {
    // Otherwise use regular SSO flow
    const redirectUri = `${window.location.origin}${routes.auth.sso.provider.callback(provider)}`
    const loginUrl = `/api/auth/sso/${provider}?redirect_uri=${encodeURIComponent(redirectUri)}`
    result = await fetchJson<SSOProviderResponse>(loginUrl, {
      method: "POST",
      body: { provider },
    })
  }
  return result
}

/**
 * Handles the callback from Single Sign-On (SSO) providers.
 */
export const ssoCallback = async (queryString: string, provider: string) => {
  const result = await fetchJson<SSOCallbackResponse>(
    `/api/auth/sso/${provider}/callback?${queryString}`,
    {
      method: "GET",
    }
  )
  return result
}
